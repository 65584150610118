.dropdown-menu.show {
  color: white;
  background-color: rgb(31, 33, 37);
  border: 0.2em solid white;
  /* inset: -13px auto auto 0px !important; */
  /* width: 98%; */
}
.dropdown_scroll::-webkit-scrollbar {
  overflow-y: auto;
  overflow-x: auto;
  height: 4px;
  width: 4px;
  display: scroll;
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
#dropdown-button-dark-example2 {
  width: 98%;
  height: 3.2em;
  background-color: transparent;
}
#dropdown-button-dark-example3 {
  width: 98%;
  height: 3.2em;
  background-color: transparent;
}
#dropdown-button-dark-example4 {
  width: 9em;
  height: 3.2em;
  background-color: transparent;
}
.modal_dropdown {
  width: 98%;
}
#double_field {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  width: 70%;
  margin: 0 auto;
}
.MuiSelect-select {
  text-align: left !important;
}
.input-group {
  width: 7rem !important;
}
.upload-data {
  display: flex;
  gap: 1em;
  top: 3.5rem;
  right: 18rem;
  position: fixed;
}
.custom-file-label {
  color: white;
  background-color: rgb(31, 33, 37);
  /* height: 1.4em;
    margin-top: 20px;
    font-size: 14px;
    line-height: 6px;
    text-align: center; */
}
.download-sample {
  /* height: 2.3em;
    margin-top: 1px;
    background-color: transparent;
    border: 0.5px solid lightgrey;
    border-radius: 4px;
color: white; */
  height: 1.4em;
  margin-top: 20px;
  font-size: 14px;

  line-height: 3px;
  /* padding-bottom: 20px; */
  background-color: transparent;
  border: 0.5px solid lightgrey;
  border-radius: 4px;
  color: white;
}
.download-sample:active {
  background-color: #ffc107;
}
.react-bootstrap-table td.react-bs-table-no-data {
  position: relative;
  text-align: unset !important;
  padding-left: 40vw;
}
