.breadcrumb {
  background: transparent;
  margin: 0 0 5rem 0;
  padding: 0;
}

.breadcrumb ol {
  margin: 0;
}

.breadcrumb li a,
.breadcrumb li {
  font-size: 22px;
  color: rgb(31, 33, 37);
  font-weight: 500;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: ">";
  content: "\f178";
  font-family: "Font Awesome 5 Pro";
  padding-right: 15px;
  margin-top: 2px;
  color: #fff;
}

.breadcrumb li {
  padding: 0 10px 0px 0px;
}

.breadcrumb-item.active {
  color: #fff;
}

.custom-form-box {
  position: relative;
  display: block;
  margin-bottom: 23px;
}

.custom-form-box input {
  border: 1px solid hsl(0deg 0% 44% / 63%);
  background: rgb(31, 33, 37);
  color: white;
  color-scheme: light;
  font-size: 14px;
  padding: 10px 15px;
  height: 50px;
  font-weight: 500;
}

.custom-form-box input:focus {
  background: rgb(31, 33, 37);
  /* box-shadow: 0 0 0px 3px #0f8bfe; */
  box-shadow: 0 0 0px 1px grey;
  border: 1px solid #0000;
  color: white;
  /* font-size: 10px !important; */

}
.was-validated .form-control:valid {
  background-image: none;
  border-color: grey;
}
input[type=Date].form-control, input[type=datetime-local].form-control, input[type=month].form-control, input[type=time].form-control{
  text-transform: uppercase;
  font-family: serif;
}
.custom-form-box span {
  color: rgb(255 255 255 / 30%);
  position: absolute;
  top: 37%;
  transform: translateY(-50%);
  left: 10px;
  /* font-size: 14px !important; */
  transition: all 0.5s;
  padding: 0 5px;
  font-weight: 500;
  cursor: text;
}
/* .custom-form-box span input:focus {
  color: rgb(255 255 255 / 30%);
  position: absolute;
  top: 37%;
  transform: translateY(-50%);
  left: 10px;
  transition: all 0.5s;
  padding: 0 5px;
  font-weight: 500;
  cursor: text;
} */
/* .custom-form-box span .label_text input:focus{
  color: white !important;
    position: absolute !important;
    font-size: 10px !important;
    top: 28px !important;
    background-color:#1f2125 !important;
    font-weight: 400;
    transform: translateY(-40px);
}  */
 .label_text{
  color: white !important;
  position: absolute !important;
  font-size: 14px !important;
   top: 25px !important; 
  background-color:#1f2125 !important;
  font-weight: 400;
   /* transform: translateY(-40px) !important;   */
 } 
 /* #type:focus ~ .label_text, */
 #type:valid ~ .label_text,
 #comment:valid ~ .label_text, 
 #available_quantity:focus ~ .label_text,
 #available_quantity:valid ~ .label_text,
 #quantity:valid ~ .label_text,
 /* #sell_quantity:hover ~ .label_text, */
 #sell_quantity:focus ~ .label_text,
 #sell_quantity:valid ~ .label_text,
 #quantity:focus ~ .label_text,
 #purchase_price:valid ~ .label_text,
 #purchase_price:focus ~ .label_text,
 #current_nav:valid ~ .label_text,
 #current_nav:focus ~ .label_text,
 #created_date:valid ~ .label_text,
 #sell_nav:valid ~ .label_text,
 #sell_nav:focus ~ .label_text,
 #sell_date:valid ~ .label_text,
 #buy_date:valid ~ .label_text,
 #comment:focus ~ .label_text,
 #game:focus ~ .label_text,
 #game:valid ~ .label_text,
 #game_detail:focus ~ .label_text,
 #game_detail:valid ~ .label_text,
 #host:focus ~ .label_text,
 #host:valid ~ .label_text,
 #venue:focus ~ .label_text,
 #venue:valid ~ .label_text,
 #date:focus ~ .label_text,
 #date:valid ~ .label_text,
 #creditor:focus ~ .label_text,
 #creditor:valid ~ .label_text,
 #player:focus ~ .label_text,
 #player:valid ~ .label_text,
 #result:focus ~ .label_text,
 #result:valid ~ .label_text,
 #exchange_rate:focus ~ .label_text,
 #exchange_rate:valid ~ .label_text,
 #amount:focus ~ .label_text,
 #amount:valid ~ .label_text,
 #receiver:focus ~ .label_text,
 #receiver:valid ~ .label_text,
 #sender:focus ~ .label_text,
 #sender:valid ~ .label_text,
 #w_name:focus ~ .label_text,
 #w_name:valid ~ .label_text,
 #e_name:focus ~ .label_text,
 #e_name:valid ~ .label_text

 

 {
   color: white !important;
   position: absolute;
   font-size: 10px !important;
   top: 32px !important;
   background-color:#1f2125 !important;
   font-weight: 400;
 }
  /* .p .label_text:focus{
  color: white !important;
  position: absolute !important;
  font-size: 10px !important;
  top: 10px !important;
  left: 12px;
  background-color:#1f2125 !important;
  font-weight: 400;
  transform: translateY(-40px) !important; 
 }    */
 /* .form-control-placeholder {
  position: absolute;
  top: 0;
  padding: 7px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5;
}

.form-control:focus + .form-control-placeholder,
.form-control:valid + .form-control-placeholder {
  font-size: 75%;
  transform: translate3d(0, -100%, 0);
  opacity: 1;
} */
.custom-form-box input:focus+span,
.custom-form-box.focus-add span {
  transform: translateY(-40px);
  color: rgb(31, 33, 37);
  top: 28px !important;
}

.custom-form {
  max-width: 500px;
  margin: 0 auto;
}

.custom-form h4 {
  text-align: center;
  font-size: 28px;
  color: white;
  margin-bottom: 40px;
  font-weight: 400;
  font-family: Inter,Helvetica,Arial;

}

.btn-gray {
  display: block;
  text-align: center;
  color: white;
  /* width: 100%; */
  background:  rgb(102, 102, 102);
  box-shadow: -5px -5px 26px hsl(247deg 4% 55% / 26%),
    18px 25px 34px hsl(0deg 0% 0% / 73%);
  /* font-size: 22px; */
  font-weight: 500;
  /* padding: 1%; */
  transition: all 0.5s;
  border-radius: 15px;
}

.btn-gray:hover {
  color: white;
  transition: all 0.5s;
  box-shadow: none;
}

.subheader-name-filter {
  display: flex;
  margin-left: 10%;
  margin-top: 1rem;
  padding-bottom: 1rem;
}