.success-msg {
  padding: 5%;
  margin: 0 auto;
  text-align: center;
}

.success-msg span {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto 30px;
}

.success-msg span img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.success-msg h4 {
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.2px;
  margin-bottom: 15px;
}

.success-msg p {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 50px;
}