nav.mb-5 .breadcrumb {
  margin: 0;
}

.table-heading-wrap {
  display: flex;
  padding: 0 25px;
  margin-right: 4%;
  align-items: center;
}

.table-heading-wrap h5 {
  font-size: 30px;
  display: flex;
  align-items: center;
  color: white;
  margin: 0 auto 0 0;
}

.table-heading-wrap .btn-gray {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  height: 43px;
  padding: 0;
  font-size: 18px;
}

.table-heading-wrap h5 span {
  display: block;
  margin-right: 10px;
  width: 25px;
  height: 30px;
}

.table-heading-wrap h5 span img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  vertical-align: top;
}

.assets-table-item .react-bootstrap-table-pagination {
  display: flex;
}

.assets-table-item {
  background: #222429;
  border-radius: 44px;
  padding: 30px 0 0;
  /* box-shadow: -2px -2px 35px hsl(0deg 0% 100% / 13%), */
  /* 21px 25px 43px hsl(0deg 0% 0% / 60%); */
  margin-bottom: 10px;
}

.assets-table-item .table th {
  padding-top: 30px;
}
