/* Fixed sidenav, full height */
.sidenav {
    height: 100%;
    width: 200px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    padding-top: 20px;
  }
  
  /* Style the sidenav links and the dropdown button */
  .sidenav a, .dropdown-btn {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;
    border: none;
    background: none;
    width:100%;
    text-align: left;
    cursor: pointer;
    outline: none;
  }
  
  /* On mouse-over */
  .sidenav a:hover, .dropdown-btn:hover {
    color: #f1f1f1;
  }
  
  /* Main content */
  .main {
    margin-left: 200px; /* Same as the width of the sidenav */
    font-size: 20px; /* Increased text to enable scrolling */
    padding: 0px 10px;
  }
  
  /* Add an active class to the active dropdown button */
  .active {
    background-color: green;
    color: white;
  }
  
  /* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
  .dropdown-container {
    display: none;
    background-color: #111;
    padding-left: 8px;
  }
  
  /* Optional: Style the caret down icon */
  .fa-caret-down {
    float: right;
    padding-right: 8px;
  }

  .sidenav a span,
  .sidenav .dropdown-btn span {
    padding: 4%;
    margin-top: -3px;
    position: absolute;
    font-size: 16px;
    color: #fff;
  }

  .sidenav a .MuiSvgIcon-root {
    font-size: 25px;
    color: '#FFC107';
  }

  .MuiSvgIcon-fontSizeMedium {
    font-size: 26px;
    color: rgb(255, 193, 7);
  }

  .mui-dropdown-arrow {
    float: right;
    margin-top: 6px;
  }

  .sidenav button.active {
    background-color: unset;
    border: unset;
  }

  .active-lnk {
    border: 1px solid #ffc107 !important;
  }
  .active-lnk span {
    border-color: unset;
  }

  .sidenav hr {
    background-color: gray;
    height: 0.01em;
    width: 100em;
  }