.login {
  min-height: 100vh;
  background: #1f2125;
  display: flex;
  align-items: center;
}

.login .row {
  padding-top: 10rem;
}

.login:after {
  content: "";
  width: 100%;
  position: absolute;
  height: 230px;
  top: -30px;
  left: 0;
  background-image: url(../../assets//images/loginborder.png);
}

@media only screen and (min-width:0px) and (max-width: 995px) {
  .div {
    margin-top: 5%;
    margin-left: 15%;
  }
}

/* .a {
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 700px;
  align-items: center;
  height: 80px;
  border-radius: 15px;
  text-decoration: none;
  font-size: 18px;
  color: white;

} */
input {
  width: 100%;
  height: 90px;
  border-radius: 14px;
  padding-left: 70px;
  margin-top: 3%;
  border: 1px solid darkgray;
  margin-bottom: 3%;
  font-size: 23px;
  font-family: 'Inter', sans-serif;
  /* font-family: "Neue Haas Grotesk Display Pro"; */
  max-width: 700px;
  color: darkgray;
  font-weight: 500;
}

.textField {
  background: transparent;
  border: 1px solid darkgray;
  width: 70%;
  margin-bottom: 3%;
  height: 10vh;
  border-radius: 14px;
  padding-left: 70px;
  font-size: 19px;
  font-family: 'Inter', sans-serif;
  /* font-family: "Neue Haas Grotesk Display Pro"; */
  max-width: 600px;
  color: darkgray;
  font-weight: 500;
}

.inputdetail {
  position: relative;
}

.inputdetail span {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.inputdetail {
  margin-bottom: 30px;
}

.a {
  background: transparent;
  border: 1px solid darkgray;
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 70%;
  align-items: center;
  margin-top: 3%;
  height: 10vh;
  border-radius: 15px;
  text-decoration: none;
  font-size: 18px;
  color: darkgray;

}

.loginbtn a:hover {
  box-shadow: none;
  transition: all 0.5s;
}

.css-1oqqzyl-MuiContainer-root {
  max-width: 1600px !important;
}

.loginbtn {
  margin-top: 70px;
}

.loginbtn a span {
  margin-left: 17px;
}

.loginimg img {
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-bottom: 20px;
}

.loginimg {
  text-align: center;
}

.loginimg p {
  font-size: 24px;
  font-family: 'Inter', sans-serif;
  /* font-family: "Neue Haas Grotesk Display Pro"; */
  color: #ffffff;
  font-weight: 500;
  max-width: 460px;
  margin: auto;
  opacity: 0.75;
  letter-spacing: 1px;
}

.owl-nav {
  display: none;
}

.owl-dots button span {
  width: 44px !important;
  background: #707070 !important;
  height: 2px !important;
}

.owl-dots button.active span {
  background: white !important;
}

.owl-dots {
  margin-top: 10;
  margin-top: 43px;
}

.MuiContainer-root.MuiContainer-maxWidthLg.css-1oqqzyl-MuiContainer-root {
  margin-top: 100px;
}