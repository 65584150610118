.sidebar-admin-container::-webkit-scrollbar {
    width: 4px;
    /* overflow-y: hidden !important; */
    /* height: 100px !important; */
    overflow-y: auto;
    overflow-x: auto;
    height: 4px;
    display: scroll;
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}
