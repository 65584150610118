#accouting-form
{
    margin-top: 3em;
    margin-bottom: 0.3em;
}
#accounting-form-details
{
    margin-top:-4em;
    margin-right:60em;
    display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 100px);
}