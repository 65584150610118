/* .css-x2l1vy-MuiInputBase-root-MuiOutlinedInput-root {
  color: white
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: white
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  color: 'white !important'
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: 'white !important'
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  color: 'white !important'
}

.css-1ptx2yq-MuiInputBase-root-MuiInput-root {
  color: 'white !important'
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  color: 'white !important'
}

.css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
  border-bottom: '2px solid grey !important '
}

.css-1ptx2yq-MuiInputBase-root-MuiInput-root:before {
  border-bottom: '2px solid white !important'
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: 'white !important'
} */

.settings-dropdown-menu {
  position: absolute;
  display: inline-block;
}

.settings-dropdown a:hover {
  background-color: #f1f1f1;
}

.settings-dropdown-btn:hover {
  background-color: #f1f1f1;
}

.settings-dropdown-content {
  display: block;
  position: relative;
  text-align: left;
  background-color: #f9f9f9;
  min-width: 115px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.settings-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.settings-dropdown-content > button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border: 0px;
  text-align: left;
  min-width: 100%;
  background-color: #f9f9f9;
}
