
.modal-dialog h4 {
  font-size: 28px;
}

.modal-dialog {
  font-size: 15px;
  color: white;
  font-weight: 600;
  text-align: center;
  font-weight: bold;
  }

 
  