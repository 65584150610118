@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500;900&display=swap");
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  /* height: 100vh; */
  background: #1f2125;
  /* #1f2125; */

  min-width: 1240px;
  overflow-x: hidden;
}
/* body::-webkit-scrollbar {
  overflow-y: auto;
  overflow-x: auto;
  height: 0px;
  width: 0px;
  display: scroll;
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
} */
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  flex-grow: 1;
  overflow-x: auto;
}
@media screen and (min-width: 300px) {
  .col-md-2 {
    max-width: 15rem !important;
  }
  .col-lg-12 {
    flex-grow: 1;
    /* flex:60 0 83.333333%;
    max-width:calc(100vw - 15rem) */
  }
}
@media screen and (min-width: 300px) {
  .col-md-1 {
    max-width: 5em !important;
    margin-left: -0.9em !important;
  }
  /* .col-lg-12{
    flex:60 0 83.333333%;
    max-width:calc(100vw - 5rem);
} */
}

/* @media screen and (min-width:1510px) {
  .table-responsive{
    height: 75vh !important;
  }
 
} */
/* @media (min-width: 1000px) and (max-width: 1468px) {
  body {
    
  height: 38em;
  overflow-y: hidden;
  } 
} */
/* .sidebar-admin-container {
  width: 13em;
  }  
   .col-lg-10{
    position:absolute;
    left: 223px;
  } */
/* .row{
    margin-right: -226px;
    margin-left: -15px;
  }  */
/* @media (min-width: 1600px) and (max-width: 2868px) {
  .sidebar-admin-container {
      width: 14em;
      }
    } */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pms-dashboard {

}

.pms-dashboard .dashboard-widget-footer {
  text-align: right;
}

.pms-dashboard table {
  margin-bottom: 0px;
}

.pms-dashboard table tr td {
  border: 0px;
  min-height: unset;
  height: unset;
  padding: 3px 10px;
}

.dashboard-modal .modal-header {
  color: #000;
}

.dashboard-modal .modal-body {
  text-align: left;
}

.pms-dashboard .card {  
  background-color: #97989b;
}

.pms-dashboard .card-header {
  font-weight: bold;
}

.pms-dashboard .card-body {
  background-color: #1f2125;
  margin-left: 10px;
  margin-right: 10px;
}

.pms-dashboard .card-zoom {
  float: right;
}

.pms-dashboard .card-zoom a {
  border: 0px;
}

.pms-dashboard .dashboard-widget-footer {
  font-weight: bold;
}

.pms-dashboard .dashboard-widget-footer span {
  margin-right: 20px;
}

.pms-dropdown-menu {
  background: rgb(31, 33, 37) !important;
  color: white !important;
  width: 13em;
}